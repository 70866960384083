.analyzeHeader {
  color: #555a6e;
  font-size: 32px;
  font-weight: 700;
}

.analyzeOptionSpacer {
  width: 50px;
}

.analyzeOption {
  align-items: center;
  background-color: #dbdff4;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: center;
  width: 160px;
}

.analyzeOption > span {
  color: #555a6e;
  font-size: 24px;
  font-weight: 700;
  margin-top: 4px;
}

.analyzeOption > svg {
  height: 60px;
  width: 60px;
}

.analyzeOptionSelected {
  background-color: #e0c7ff;
}

.optionContainer {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  position: relative;
  z-index: var(--chakra-zIndices-dropdown);
}
